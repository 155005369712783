@import "common.scss";

.receipt-modal .modal-content {
    background-color: transparent;
    border: none;
}

.payment-receiptx {
    //height: 600px;
    position: relative;
    overflow: hidden;
    

    .receipt-bg {
        position: absolute;
        height: 100%;
        width: 100%;
        //top: -30px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        width: 100%;

        h2 {
            font-size: 24px;
            margin-top: 10px;
            margin-bottom: 0;
        }

        .logo {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            border: 1px solid #d4d4d4;
            border-radius: 30px;

            div {
                width: 45px;
                height: 45px;
                margin-left: auto;
                margin-right: auto;
                background-image: url(../resources/logoSq.png);
                background-size: contain;
                background-repeat: no-repeat;
        
                &:focus {
                    outline: none;
                }
            }
        }

        .swish-logo {
            width: 59px;
            height: 18px;
            margin-left: auto;
            background-image: url(../resources/swish_logo_secondary_RGB.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
        
    
        .creation-date {
            color: #7a7a7a;
            font-size: 15px;
            border-bottom: 1px solid #ccc;
            width: 80%;
            text-align: center;
            padding-bottom: 32px;
        }

        .tuple {
            display: flex;
            width: 250px;
            margin-top: 20px;
            align-items: center;

            .tuple-title {
                color: #7a7a7a;
                font-size: 13px;
            }
            .tuple-value {
                flex-grow: 1;
                margin-right: 0;
                text-align: end;
            }
        }

        .payment-card {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            img {
                height: 23px;
                border: 1px solid #ddd;
                border-radius: 2px;
            }
            .details {
                display: flex;
                flex-direction: column;
                margin-left: 5px;
                .last4 {          
                    .dots {
                        font-size: 30px;
                        line-height: 14px;
                        position: relative;
                        top: -3px;
                    }
                }
            }
        }

        .info-text {
            margin-top: 10px;
            font-size: 13px;
            width: 200px;
            text-align: center;
            color: #7a7a7a;
        }

        .thank-you-text {
            margin-top: 40px;
            color: #1cb140;
            font-size: 20px;
            font-weight: 800;
        }

        .close-button {
            width: 110px;
            margin-top: 30px;
            @include rounded-primary-button;
        }

        .bottom-line {
            color: #7a7a7a;
            width: 100%;
            font-size: 13px;
            margin-top: 40px;
            border-top: 1px solid #ccc;

            span:first-child {
                margin-left: 2%;
            }
            span:last-child {
                float: right;
                margin-right: 2%;
            }
        }

    }

    


}