@import "common.scss";
.profile-form {
    padding-top: 20px;
    padding-bottom: 20px;

    .form-label {
        font-family: 'Assistant';
        font-size: 13px;
        text-transform: uppercase;
        color: #666;
        margin-bottom: 0;

        &.profile-language-label {
            margin-bottom: 10px;
        }
    }

    .button-row {
        display: flex;
        .submit-button {
            @include confirm-button;
            width: 60%;
        }
        .cancel-button {
            @include cancel-button;
            margin-left: 10px;
            width: 40%;
        }
    }



 
}