@import "common.scss";

.app-root {
    display: flex; // 3 columns, main col is the main-view, side-col on each side

    .side-col {
        flex-grow: 1;
    }

    .bottom-overlay {
        width: 100%;
    }

    .main-view {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        
        position: relative;


        .bg { // the background that is used on each view
            @include sthlm-bg;
        }
    
        .cover { // the semi-transparent overlay on the bg
            @include bg-overlay;
        }

        .containr { 
            position: relative;
            width: 100%;
            flex-grow: 1;
            overflow-x: hidden;

            .transition-group {
                width: 100%;

                .page {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    //bottom: 0;

                    .content {
                        position: relative;
                    }
                }  // page

                &.left  .page-enter {
                    transform: translateX(100%);
                  }
                  
                &.left  .page-enter-active {
                    transform: translateX(0%);
                    transition: transform 400ms ease-in-out;
                  }
                  
                &.right  .page-exit {
                    transform: translateX(0%);
                  }
                  
                &.right .page-exit-active {
                    transform: translateX(100%);
                    transition: transform 400ms ease-in-out;
                  }

                .profile-page {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    //bottom: 0;

                    .content {
                        position: relative;
                    }

                    &.profile-enter {
                        transform: translateY(-100%);
                    }
                    
                    &.profile-enter-active {
                        transform: translateY(0);
                        transition: transform 2000ms ease-in-out;
                    }
                    
                    &.profile-exit {
                        transform: translateY(-50%);
                    }
                    
                    &.profile-exit-active {
                        transform: translateY(0);
                        transition: transform 2000ms ease-in-out;
                    }
                }  
                
                /*.profile-view-enter {
                    transform: translateY(-50%);
                }
                profile-view-enter-active {
                    transform: translateY(0%);
                    transition: transform 2000ms ease-in-out;
                }

                */

            } // transition-group
        } // containr

        .init-screen-enter {
            opacity: 1;
          }
          .init-screen-enter-active {
            opacity: 1;
            transition: opacity 400ms;
          }
          .init-screen-exit {
            opacity: 1;
          }
          .init-screen-exit-active {
            opacity: 0;
            transition: opacity 400ms;
          }
          

    } // main-view

} // app-root


/* overlay of left & right columns to cover the side menu when it is 
   transitioned out*/
.app-root {
    &.menu-cover {
        position: absolute;
        top: 0;
        width: 100%;
        pointer-events: none;

        .main-view {
            background-image: none;
        }

        .side-col {
            background-color: #afafaf;
            z-index: 10000;

            &:first-child {
                //border-right: 3px solid #9a9a9a; add in media query...
            }
            &:last-child {
                //border-left: 3px solid #9a9a9a;
            }
        }
    }
}


.testar {
    width: 100%;
    height: 100%;
    background-color: greenyellow;
    position: absolute;
    top: -94%;

    button {
        position: absolute;
        bottom: 0;
    }

    &.show {
        background-color: red;
        top: 0;
        transition: top 400ms;
    }
    &.hide {
        
        top: -94%;
        transition: top 400ms;
    }
}







