@mixin sthlm-bg {
    position: absolute;
    background-image: url(../resources/sthlmBW.jpg);
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.9;
}

@mixin bg-overlay {
    position: absolute;
    background-color: var(--overlay-color);
    width: 100%;
    height: 100%;
    opacity: 0.9;
}


@mixin property-table {
        margin-top: 20px;

        .bg-plate {
            background-color: white;
            border-radius: 5px;
            box-shadow: 0 1px 7px 2px rgba(0, 0, 0, 0.1);

            h1 {
                font-size: 18px;
                font-family: 'Assistant';
                text-transform: uppercase;
                font-weight: bold;
                padding-top: 5px;
                padding-left: 20px;
                color: white;
                padding-bottom: 4px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                margin-bottom: 0;
                background-color: var(--primary-info-color);
            }
        }
        .prop-group {
            display: flex;
            width: 100%;
            padding-top: 3px;
            padding-bottom: 3px;
            align-items: baseline;
            border-top: 1px solid #ddd;
            padding-left: 20px;
            padding-right: 20px;
            
            .label {
                display: inline-block;
                font-size: 17px;
                text-transform: uppercase;
                flex-grow: 1;
                color: #777;
                font-family: 'BenchNine';
                font-weight: 300;
            }
            
            .value-group {
                text-align: right;
                .value {
                    font-size: 17px;
                }
            }
        }
    }

.line-divider-with-title {
    position: relative;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    align-items: center;
    div {
        background-color: #cccccc;
        height: 1px;
        flex-grow: 1;
        margin-left: 20px;
        margin-right: 20px;
    }
    span {
        text-transform: uppercase;
        color: #999;
        font-family: 'BenchNine';
    }
}

/* Common icon base styling for our own icons in icons.js */
.ep-icon {
    width: 50px;
    height: 50px;
    fill: transparent;
    stroke: #333333;
    stroke-width: 15px;
}





/* Used for all sliding views like history, settings, etc */
.sliding-view {
    width: 100%;
    height: 100%;
    .bg {
        @include sthlm-bg;
    }

    .cover {
        @include bg-overlay;
    }
}

.react-toggle {
    .react-toggle-track {
        background-color: #b5b5b5;
    }
    &.react-toggle--checked .react-toggle-track {
        background-color: #30bf41;
    }
    .track-text {
        font-size: 11px;
        color: white;
        position: relative;
        top: 3px;
        font-weight: bold;
    }
    &.react-toggle--focus .react-toggle-thumb {
        box-shadow: none;
    } 
}

.help-text-button {
    color: #bbb;
    &:hover {
        cursor: pointer;
        color: var(--action-color) !important;
    }
}

.confirm-cancel-button-row {
    display: flex;
    align-items: center;

    .cancel-button {
        max-width: 105px;
        margin-left: 10px;
    }
}

.centered-overlay-info-box {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 40px;
    .bg-plate {
        background-color: #555;
        color: white;
        width: 300px;
        max-width: 90%;
        border-radius: 4px;
        border: 1px solid #888;
        font-size: 13px;
        text-align: start;
        padding: 5px;

        -webkit-box-shadow: 0px 0px 8px 0px rgba(85, 85, 85, .5);
        -moz-box-shadow: 0px 0px 8px 0px rgba(85, 85, 85, .5);
        box-shadow: 0px 0px 8px 0px rgba(85, 85, 85, .5);
    }
}

/* a full width primary button */
@mixin confirm-button {
    display: block;
    border: none;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--action-color);
    color: white;
    text-align: center;
    text-transform: uppercase;
    padding: 11px 0;
    font-size: 22px;
    font-weight: bold;

    &:disabled {
        pointer-events: none;
        background-color: var(--action-color);
    }
    
    &:hover {
        cursor: pointer;
        background-color: var(--action-color-darken);
    }

    &:active {
        background-color: var(--action-color-darken) !important;
    }

    &:focus {
        background-color: var(--action-color-darken) !important;
    }
}

/* a full width secondary button */
@mixin cancel-button {
    display: block;
    width: 100%;
    background: white;
    color: #555 !important;
    border: 1px solid #bbb !important;
    text-align: center;
    text-transform: uppercase;
    padding: 11px 0;
    font-size: 22px;
    font-weight: normal;
    //margin-top: 30px;

    &:hover {
        cursor: pointer;
        background: #dddddd;

    }

    &:active {
        background: #dddddd  !important;
    }

    &:focus {
        background-color: #dddddd !important;
    }
}

@mixin rounded-primary-button {
    border-radius: 20px;
    font-family: 'Assistant';
    text-transform: uppercase;
    padding: 3px 9px;
    font-weight: bold;
    background-color: var(--action-color);
    border-color: transparent;
    color: white;

    svg {
        margin-right: 5px;
    }

    &:disabled {
        pointer-events: none;
        background-color: var(--action-color);
    }
    
    &:hover {
        cursor: pointer;
        background-color: var(--action-color-darken);
    }

    &:active, &:focus {
        background-color: var(--action-color-darken) !important;
    }
}

@mixin rounded-secondary-button {
    width: fit-content;
    border-radius: 20px;
    font-family: 'Assistant';
    text-transform: uppercase;
    padding: 3px 9px;
    font-weight: bold;
    background-color: #ffffff;
    color: var(--action-color);
    border-color: var(--action-color);

    svg {
        margin-right: 5px;
    }

    &:disabled {
        pointer-events: none;
        color: #aaaaaa;
    }
    
    &:hover {
        cursor: pointer;
        background-color: #eeeeee;
    }

    &:active, &:focus {
        background-color: #eeeeee !important;
    }
}


/* A 100px heigh header photo covered with a gradient
   typically used as background to a header text */
   .photo-header-bg {
    position: relative;
    height: 100px;
    background-image: url(../resources/sthlmBW.jpg);
    background-size: cover;
    background-position: right;

    .cover-color {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.86) 0%, rgba(0,0,0,0) 100%);
    }
}

/* A content container with the content overlayed on the photo-header-bg */
.content-container {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    //height: calc(100% - 50px); //50px is height of bottom control area
    display: flex;
    flex-direction: column;

    .scroll-container {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .button-heading {
        display: flex;
        align-items: flex-end;
        height: 100px;
        min-height: 100px;
        
        
    
        h1 {
            font-family: 'Assistant';
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
            color: white;
        }
    
        button {
            margin-bottom: 10px;
            margin-left: auto;
            white-space: nowrap;
            @include rounded-primary-button;
        }
    }
}


/* Tab componeant styling*/
.nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 20px;
    margin-bottom: 30px;
    list-style: none;
    border-bottom: 1px solid #ddd;

    .nav-item {
        min-width: 0;

        .nav-link {
            color: #555;
            padding: 0;
            padding-bottom: 5px;
            &.active {
                color: #333;
                font-weight: bold;
                border-bottom: 2px solid #333;

                .ep-icon {
                    fill: #f1f1f1;
                    stroke: var(--primary-info-color)
                }

                .header-text {
                    color: #333;
                }
            }
        }

        .nav-header {
            display: flex;
            flex-wrap: nowrap;
            align-items: baseline;
            justify-content: center;

            .ep-icon {
                stroke: #9c9c9c;
                width: 20px;
                height: 20px;
                margin-right: 5px;
                position: relative;
                top: 3px;
            }

            .header-text {
                color: #888;
                font-family: 'BenchNine';
                font-size: 17px;
                text-transform: uppercase;
                white-space: nowrap;
            }
        }
    }
}