@import "common.scss";

.map-info-window {
    position: relative;
    display: inline-block;
    z-index: 100;
    //top: -100px;
    //left: -35px;
    //transform: translate(-50%,-100%);
    transform: translateX(-50%) translateY(-100%) translateY(-50px);
    padding: 15px 20px;
    padding-right: 30px;
    padding-bottom: 15px;
    min-width: 220px;
    width: fit-content;
    //height: 60px;
    background-color: white;
    //border: 1px solid #777;
    border-radius: 8px;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    .title {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 500;
        font-size: 15px;
    }
    .provider {
        color: #666;
        font-size: 11px;
        text-transform: uppercase;
    }
    .address {
        margin-top: 2px;
        margin-bottom: 5px;
        color: #555;
        font-size: 13px;
    }

    .outlet-status {
        width: 100%;
        display: flex;
        margin-top: 5px;
        margin-bottom: 3px;
        align-content: flex-end;
        align-items: flex-end;

        span:first-child {
            
            font-size: 11px;
            text-transform: uppercase;
            color: #666;
        }

        span:last-child {
            margin-left: auto;
            text-transform: uppercase;
            font-size: 9px;
            color: white;
            font-weight: 700;
            background-color: #129077;
            border-radius: 6px;
            padding: 2px 4px;

            &.STATUS_FREE {
                background-color: #129077;
            }
            &.STATUS_FULL {
                background-color: #F14B53;
            }
            &.STATUS_CROWDED {
                background-color: #FFD908;
                color: #333;
            }
            
        }

    }

    .outlets {
        color: #555;
        font-size: 12px;
        margin-bottom: 10px;
        .group {
            margin-bottom: 5px;
            .amps {
                display: block;
                font-weight: 500;
                color: #333;
            }
            .stats {
                display: block;
                font-size: 11px;
            }
        }
    }

    .tariffs {
        
        border-top: 1px solid #ccc;
        font-size: 12px;
        .tariffs-title {
            margin-top: 5px;
            font-size: 11px;
            text-transform: uppercase;
            color: #666;
        }
    }

    .support-row {
        cursor: pointer;
        margin-top: 5px;
        .support-title {
            text-transform: uppercase;
            color: #666;
            margin-bottom: 3px;

            span {
                margin-left: 10px;
            }
        }
        .support-data {
            div {
                margin-bottom: 3px;
            }
            svg {
                margin-right: 5px;
                color: var(--action-color);
            }
        }
    }

    .restrictions-apply-title {
        display: inline-block;
        font-weight: bold;
        padding-left: 3px;
        padding-right: 3px;
        margin-top: 5px;
        margin-bottom: 2px;
        font-size: 11px;
            text-transform: uppercase;
            color: rgb(255, 251, 251);
            background-color: #F14B53;
    }
    .restrictions-apply-text {
            font-size: 11px;
    }

    .btn-row {
        width: 100%;
        display: flex;

        .ovale-button {
            @include rounded-primary-button;
            display: inline-block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;

        }
    }

    .close-button {
        position: absolute;
        display: flex;
        top: 5px;
        right: 5px;
        width: 80px;
        height: 80px;
        font-size: 20px;
        font-weight: 100;
        color: #ccc;

        svg {
            font-size: 20px;
            margin-left: auto;

            &:hover {
                color: #666;
                cursor: pointer;
            }
        }

    }
    
}

