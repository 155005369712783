.side-bar {
    $top-area-height: 100px;

    font-family: 'BenchNine';
    text-transform: uppercase;
    .top-area-bg {
        background-color: var(--action-color);
        height: $top-area-height;
        filter: brightness(0.7);
        &:focus {
            outline: none;
        }
    }

    .top-area-content {
        height: $top-area-height;
        position: relative;
        top: -$top-area-height;
        background-image: url(../resources/eploggaWhite.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom -25px left 65px;

        &:focus {
            outline: none;
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        position: relative;
        top: -$top-area-height;
        &:focus {
            outline: none;
        }

        li {
            height: 60px;
            border-bottom: 1px solid #efefef;

            a {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                color: #bbbbbb;

                &:hover {
                    background-color: #f3f3f3;
                    text-decoration: none;
                }

                .item {
                    margin-left: 30px;

                    path {
                        fill: #f1f1f1;
                        stroke: var(--primary-info-color);
                        stroke-width: 20px;
                    }

                    span {
                        margin-left: 10px;
                        color: #555555;
                    }
                }
            }
        }

        .language-block {
            margin-top: 20px;
            margin-left: 30px;
            color: #555555;

            &:focus {
                outline: none;
            }

            .lang-row {
                cursor: pointer;
            }

            span {
                font-family: sans-serif;
                text-transform: capitalize;
                font-size: 15px;
                color: #777;
            }

            .flag {
                width: 15px;
                height: 15px;
                margin-right: 7px;
                position: relative;
                top: 2px;
                display: inline-block;
                background-size: cover;
            }
            .flag-uk {
                background-image: url(../resources/united-kingdom-flag-round-icon-64.png);
            }
            .flag-se {
                background-image: url(../resources/sweden-flag-round-icon-64.png);
            }
        }

        
    }

    .version {
        &:focus {
            outline: none;
        }
        margin-left: 3px;

        span {
            font-size: 12px;
            position: relative;
            margin-left: 10px;
            top: -3px;

            &:hover {
                cursor: pointer;
                color: var(--action-color);
            }
        }
    }

}




/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #ffffff;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
