@import "common.scss";

.receipt-modal .modal-content {
    background-color: transparent;
    border: none;
}

.payment-receipt {
    height: 600px;
    position: relative;
    overflow: hidden;
    

    .receipt-bg {
        position: absolute;
        
        stroke: #ddd;
        //top: -30px;
    }

    .content {
        height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        width: 100%;
        padding: 25px 2%;

        h2 {
            font-size: 24px;
            margin-top: 10px;
            margin-bottom: 0;
        }

        .logo {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            border: 1px solid #d4d4d4;
            border-radius: 30px;

            div {
                width: 35px;
                height: 35px;
                margin-left: auto;
                margin-right: auto;
                background-image: url(../resources/logoSq.png);
                background-size: contain;
                background-repeat: no-repeat;
        
                &:focus {
                    outline: none;
                }
            }
        }

        .swish-logo {
            width: 59px;
            height: 18px;
            margin-left: auto;
            background-image: url(../resources/swish_logo_secondary_RGB.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
        
    
        .creation-date {
            color: #7a7a7a;
            font-size: 15px;
            border-bottom: 1px solid #ccc;
            width: 250px;
            text-align: center;
            padding-bottom: 12px;
        }

        .tuple {
            display: flex;
            width: 250px;
            margin-top: 20px;
            align-items: center;

            .tuple-title {
                color: #7a7a7a;
                font-size: 13px;
            }
            .tuple-value {
                flex-grow: 1;
                margin-right: 0;
                text-align: end;

                .amount-before-refund {
                    position: absolute;
                    right: 0;
                    color: #777;
                    bottom: 19px;
                    font-size: 12px;
                    text-decoration: line-through;
                }
            }

            &.refund-list {
                display: block;
                .tuple-value {
                    text-align: start;   
                    font-size: 15px;

                    .time-val {
                        margin-left: 3px;
                        font-size: 13px;
                        color: #777;
                    }
                    .amount-val {
                        float: right;
                    }
                }   
            }
        }

        .payment-card {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            img {
                height: 23px;
                border: 1px solid #ddd;
                border-radius: 2px;
            }
            .details {
                display: flex;
                flex-direction: column;
                margin-left: 5px;
                .last4 {          
                    .dots {
                        font-size: 30px;
                        line-height: 14px;
                        position: relative;
                        top: -3px;
                    }
                }
            }
        }

        .info-text {
            margin-top: 10px;
            font-size: 13px;
            width: 200px;
            text-align: center;
            color: #7a7a7a;
        }

        .thank-you-text {
            margin-top: 40px;
            color: #1cb140;
            font-size: 15px;
            font-weight: 800;
        }

        .close-button {
            width: 110px;
            margin-top: 30px;
            @include rounded-primary-button;

            .spinner-border {
                width: 20px;
                height: 20px;
            }

            &:disabled {
                background-color: #aaa;
            }
        }
        

        .bottom-line {
            color: #7a7a7a;
            width: 278px;
            font-size: 13px;
            margin-top: auto;
            margin-bottom: 30px;
            border-top: 1px solid #ccc;

            span:first-child {
                margin-left: 2%;
            }
            span:last-child {
                float: right;
                margin-right: 2%;
            }
        }

    }

    


}