.nav-bar {
    position: relative;
    background-color:#303030;
    height: 50px;
    color: #fefefe;
    display: flex;
    align-items: center;

    .nav-bar-icon {
        font-size: 32px;
        position: absolute;
        left: 13px;
    }

    .title {
        font-family: 'BenchNine';
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        font-size: 30px;
        font-weight: bold;
    }
    
    
}