.start-charging-view {
    min-height: 620px;
    padding-bottom: 30px;

    .nav {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .info-text {
        width: 100%;
        text-align: center;
        font-size: 14px;
        padding: 0 20px;
        margin-bottom: 40px;
        color: #555;
    }

    .slot-charging-header {
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 30px;
        color: #333;
        font-weight: 700;

        .ep-icon {
            stroke: var(--primary-info-color);
            width: 20px;
            height: 20px;
            margin-right: 5px;
            position: relative;
            top: 3px;
        }

        .header-text {
            font-family: 'BenchNine';
            font-size: 17px;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }

    .unit-name {
        font-family: "Assistant";
        font-size: 34px;
        margin-top: -30px;
        margin-bottom: 40px;
        font-weight: normal;
        text-align: center;
    }

    .selected-payment-radio-container {
        display: flex;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
        
        .selected-payment-radios {
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;

            .radio-button-container {
                .checkmark {
                    background-color: white;
                    border: 1px solid #aaa;
                    top: -1px;

                    &:after {
                       top: 8px;
                       left: 8px;
                       width: 8px;
                       height: 8px;
                       border-radius: 50%;
                       background: white;
                   }
                }

                &:hover input ~ .checkmark {
                    background-color: #fff;
                }
                
                input:checked ~ .checkmark {
                    background-color: var(--action-color);
                }
            }
        }
    }

    .unregistered-payment-buttons {
        .form-group {
            width: 80%;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;

            .form-label {
                font-size: 12px;
                color: #555;
                margin-bottom: 0;
            }
        }
        
    }
    


   /* .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 20px;
        margin-bottom: 30px;
        list-style: none;
        border-bottom: 1px solid #ddd;

        .nav-item {
            min-width: 0;

            .nav-link {
                color: #555;
                padding: 0;
                padding-bottom: 5px;
                &.active {
                    color: #333;
                    font-weight: bold;
                    border-bottom: 2px solid #333;

                    .ep-icon {
                        fill: #f1f1f1;
                        stroke: var(--primary-info-color)
                    }

                    .header-text {
                        color: #333;
                    }
                }
            }

            .nav-header {
                display: flex;
                flex-wrap: nowrap;
                align-items: baseline;
                justify-content: center;

                .ep-icon {
                    stroke: #9c9c9c;
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    position: relative;
                    top: 3px;
                }

                .header-text {
                    color: #888;
                    font-family: 'BenchNine';
                    font-size: 17px;
                    text-transform: uppercase;
                    white-space: nowrap;
                }
            }
        }
    }
    */
      
    .swish-from-other {
        margin-top: 25px;
        text-align: center;
        font-size: 14px;

        span {
            display: block;
            text-decoration: underline;
            color: var(--action-color-darken);
            &:hover {
                font-weight: bold;
            }
        }
    }

    .pay-button {
        display: block;
        border: none;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        background-color: var(--action-color);
        color: white;
        text-align: center;
        text-transform: uppercase;
        padding: 11px 0;
        font-size: 22px;
        font-weight: bold;

        &:disabled {
            pointer-events: none;
            background-color: var(--action-color);
        }
        
        &:hover, &:focus {
            cursor: pointer;
            background-color: var(--action-color-darken);
        }

        &:active {
            background-color: var(--action-color-darken) !important;
        }
    }
    
}

.payment-info-modal {
    .ok-btn {
        display: block;
        border: none;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        background-color: var(--action-color);
        color: white;
        text-align: center;
        text-transform: uppercase;
        padding: 11px 0;
        font-size: 22px;
        font-weight: bold;

        &:disabled {
            pointer-events: none;
            background-color: var(--action-color);
        }
        
        &:hover, &:focus {
            cursor: pointer;
            background-color: var(--action-color-darken);
        }

        &:active {
            background-color: var(--action-color-darken) !important;
        }
    }
    
}




