.scan-outlet-view {
    min-height: 500px;
    text-align: center;
    //width: 80%;
    //max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;

    .header-row {
        font-size: 20px;
        margin-top: 90px;
        margin-bottom: 20px;
        opacity: .7;
    }

    .qr-text-input {
        display: flex;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .outlet-input {
        width: 200px;
        text-align: center;
    }

    .scan-button {
        position: relative;
        width: 45px;
        height: 50px;
        margin-left: 10px;
        

        svg {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.2s;
            fill: var(--action-color);

            &:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
        }

        
    }

    .scan-container {
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        .close-button {
            position: absolute;
            top: 3px;
            right: 18px;
            font-size: 40px;
            z-index: 10;

            path {
                fill: none;
                stroke: #ffffff;
                stroke-width: 15px;
            }

            &:hover {
                path {
                    fill: #ffffff57;

                }
            }
        }

        section section {
            // border-radius: 35px;
            video {
               // border-radius: 35px;
            }
            div {
                box-shadow: #449D44 0px 0px 0px 3px inset !important;
            }
        }
    }

    .used-outlets {
        margin-top: 60px;
        .used-outlet-block {
            margin-top: 20px;
            .used-outlet {
                text-align: left;
                margin-top: 10px;
                background-color: #fbfbfb;
                border: 1px solid #ddd;
                padding: 10px 2%;
                display: flex;
                align-items: center;

                .used-outlet-left {
                    .used-outlet-name {
                        font-size: 14px;
                    }
                    .used-outlet-location {
                        font-size: 12px;
                        color: #777;
                    }
                }

                .used-outlet-button {
                    margin-left: auto;
                    font-size: 22px;
                    color: var(--action-color);

                    &:hover {
                        font-size: 24px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    
}