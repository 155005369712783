@import "common.scss";

.help-text-modal {
    text-align: left;

    h1 {
        font-size: 20px;
    }
    p {
        font-size: 14px;
    }

    .button-row {
        display: flex;
        button {
            background: var(--action-color);
            color: white;
            text-align: center;
            text-transform: uppercase;
            margin-left: auto;

            &:hover {
                cursor: pointer;
                background: var(--action-color-darken);
            }
    
            &:active {
                background: var(--action-color-darken);
            }
        }
    }
}

.info-modal, .support-modal {
    p {
        text-align: center;
    }
    span {
        display:block;
        text-align: center;
        color: grey;
        font-size: 13px;
    }

    .button-row {
        display: flex;
        .cancel-btn {
            background: white;
            color: #555;
            border: 1px solid #bbb;
            margin-left: 10px;

            &:hover {
                cursor: pointer;
                background: #dddddd;
            }
    
            &:active {
                background: #dddddd;
            }
        }
    }
    button {
        display: block;
        height: 38px;
            border: none;
            width: 100%;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
            background: var(--action-color);
            color: white;
            text-align: center;
            text-transform: uppercase;

        &:hover {
            cursor: pointer;
            background: var(--action-color-darken);
        }

        &:active {
            background: var(--action-color-darken) !important;
        }
    }

    .support-view {
        text-align: center;
        .section-1 {
            margin-bottom: 0;
        }
        .section-2 {
            margin-top: 5px;
        }
        .provider-name {
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 18px;
            font-weight: bold;
        }

        .contact-row {
            display: flex;
            flex-direction: column;
            font-size: 13px;
            span {
                margin-top: 2px;
                margin-bottom: 2px;
            }
            svg {
                color: var(--action-color);
                margin-right: 4px;
            }
        }
    }
}

.support-modal {
    .cancel-btn {
        width: 58px;

        svg {
            color: transparent;
            stroke: var(--action-color);
            stroke-width: 26px;
            width: 24px;
            height: 24px;
        }
    }
}

.loading-modal {
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 30%;
    left: 0;

    .backplate {
        position: relative;
        z-index: 1041;
        background-color: #ffffff;
        border-radius: 10px;
        width: 80%;
        max-width: 300px;
        min-height: 200px;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(168,168,168,.5);
        -moz-box-shadow: 0px 0px 8px 0px rgba(168,168,168,.5);
        box-shadow: 0px 0px 8px 0px rgba(168,168,168,.5);

        .load-text {
            text-align: center;
            padding: 30px 25px;
        }
    }

    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 1040;
        opacity: 0.7;
        background-color: white;
    }

    .lds-ellipsis {
        position: absolute;
        bottom: 30px;
        left: calc(50% - 75px);
        width: 150px;
        height: 80px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: var(--secondary-info-color);
        z-index: 1041;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 58px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 58px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
        transform: scale(0);
        }
        100% {
        transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
        transform: scale(1);
        }
        100% {
        transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
        transform: translate(0, 0);
        }
        100% {
        transform: translate(50px, 0);
        }
    }
}

.init-screen {
    position: absolute;
    height: 100%;
    width: 100%;
    .bg {
        @include sthlm-bg;
    }
    .overlay {
        @include bg-overlay;
        background-color: #292929;
    }
    .logo-container {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 20%;
        margin-left: auto;
        margin-right: auto;
        .logo {
            width: 320px;
            height: 120px;
            margin-left: auto;
            margin-right: auto;
            background-image: url(../resources/eploggaTextColor.png);
            background-size: contain;
            background-repeat: no-repeat;
            //background-position: bottom -25px left 65px;
    
            &:focus {
                outline: none;
            }
        }
        .pitch {
            width: 100%;
            top: 130px;
            text-align: center;
            color: #fe7a01;
            text-transform: uppercase;
            font-weight: bold;

            span {
                display: inline-block;
                animation: pulsate 5s ease-out;
                animation-iteration-count: infinite; 
                animation-fill-mode: forwards;
                opacity: 0;
                

                &:nth-child(1) {
                    position: relative;
                    left: -20px;
                    animation-delay: 0s;
                }
                &:nth-child(2) {
                    animation-delay: 1s;
                }
                &:nth-child(3) {
                    position: relative;
                    animation-delay: 2s;
                    left: 20px;
                    
                }

            }

            

        }

        @keyframes pulsate {
            0% { 
                //opacity: 0;
                transform: scale(1);
                text-shadow: 0px 0px 10px #fe7a0100, 0px 0px 20px #fe7a0100;
            }
            10% { 
                opacity: 1.0;
                transform: scale(1.1);
                text-shadow: 0px 0px 10px #fe7a0177, 0px 0px 20px #fe7a0177;
            }
            20% { 
                opacity: 1;
                transform: scale(1);
                text-shadow: 0px 0px 10px #fe7a0100, 0px 0px 20px #fe7a0100;
            }
            100% { 
                opacity: 1;
                transform: scale(1);
                text-shadow: 0px 0px 10px #fe7a0100, 0px 0px 20px #fe7a0100;
            }
            
        }
    }
     
}