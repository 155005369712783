.home-view {
    width: 100%;
    height: 100%;
    position: relative;

    &.in-background { 
        overflow-y: hidden;
    }    

    .content {
        position: relative;
    }
}



