@import "common.scss";

.topup-account-form {
    .content {
        display: flex;
        height: 580px;
        padding: 30px;
        flex-direction: column;

        .swish-qr {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            text-align: center;

            .CircularProgressbar {
                width: 23px;
                height: 23px;
                position: absolute;
                bottom: 6px;
                right: 6px;
            }

            img {
                width: 70%;
                margin-top: 40px;
            }

            .cancel-button {
                margin-top: auto;
                margin-bottom: 10px;
            }
        }

        h1 {
            font-size: 30px;
            margin-bottom: 50px;
            text-align: center;
        }

        .amount-row {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 100px;

            .amount {
                font-weight: 600;
                font-size: 35px;
                width: 150px;
                text-align: center;
            }

            .amount-btn {
                font-size: 35px;
                color: #b5b5b5;
                &:hover {
                    cursor: pointer;
                    color: #999999;
                }
                &.disabled {
                    color: #dddddd;
                }
                &.disabled:hover {
                    cursor: default;
                    color: #dddddd;
                }
            }
        }

        .split-pay-button {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
            .pay-button {
                margin-top: 0;
                margin-bottom: 0;
                @include confirm-button;
            }
            .dropdown-toggle {
                padding: 0;
            }
            .dropdown-menu {
                bottom: 56px !important;
                top: auto !important;
                left: auto !important;
                right: 0 !important;
                transform: none !important;
                font-size: 14px;
            }
            #bg-nested-dropdown {
                background-color: #ffffff;
                border: 2px solid #459d44;
                color: #459d44;
                font-size: 20px;

                &::after {
                    content: none;
                }

                .button-title {
                    width: 110px;
                    white-space: break-spaces;
                    height: 40px;
                    line-height: 20px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 700;
                    
                    &::after {
                        content: none;
                    }
                }
            } 
            .no-card-info-text {
                width: 150px;
                white-space: normal;
                padding-right: 0;
                padding-top: 0;
                font-size: 12px;
            }   
        }

        .pay-button {
            margin-top: 10px;
            margin-bottom: 10px;
            @include confirm-button;
        }
        .cancel-button {
            margin-top: 10px;
            @include cancel-button;
        }

        .swish-fee-text {
            font-size: 12px;
            text-align: center;
        }

        .swish-from-other {
            margin-top: auto;
            text-align: center;
            font-size: 14px;

            span {
                display: block;
                text-decoration: underline;
                color: var(--action-color-darken);
                &:hover {
                    font-weight: bold;
                }
            }
        }

        .error-text {
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            color: red;
        }
    }    
}