@import "common.scss";
.support-view {

    .content {
        width: 80%;
        min-width: 310px;
        margin-left: auto;
        margin-right: auto;
        //min-height: 550px;
        margin-top: 20px;

        .plate {
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            background-color: white;
            border: 1px solid #eee;
            border-radius: 20px;
            max-width: 290px;
            padding: 15px;

            
        }

        h1 {
            font-size: 20px;
            color: var(--action-color);
        }

        .prov-name {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 5px;
        }

        .scan-to-see {
            font-size: 13px;
        }

        .contact-row {
            display: flex;
            flex-direction: column;
            font-size: 13px;
            span {
                margin-top: 2px;
                margin-bottom: 2px;
            }
            svg {
                color: var(--action-color);
                margin-right: 4px;
            }

            .support-button {
                width: 130px;
                margin-top: 10px;
                @include rounded-primary-button;
        
                svg {
                    color: white;
                    margin-right: 10px;
                }
            }
        }
    }

    
}