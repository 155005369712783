html {
    height: 100%;
    background-color: #afafaf;
}

html:before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #404040;
    border-bottom: 300px solid #eeebec;
  }


body {
    height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#root {
    height: 100%;
}

.app-root {
    height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --overlay-color: #ffffff;
    --action-color: #459d44;
    --action-color-darken: #347533;

    --primary-info-color: #292929; 

    --secondary-info-color: #ff8200; /* EpSpot red */
    
}


@font-face {
    font-family: 'BenchNine';
    font-weight: normal;
    src: local('BenchNine-Regular'), url(./resources/fonts/BenchNine-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'BenchNine';
    font-weight: bold;
    src: local('BenchNine-Bold'), url(./resources/fonts/BenchNine-Bold.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'BenchNine';
    font-weight: 300;
    src: local('BenchNine-Light'), url(./resources/fonts/BenchNine-Light.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Mouse';
    src: local('MouseMemoirs-Regular'), url(./resources/fonts/MouseMemoirs-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Assistant';
    src: local('Assistant-Regular'), url(./resources/fonts/Assistant-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Assistant';
    font-weight: 300;
    src: local('Assistant-Light'), url(./resources/fonts/Assistant-Light.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Assistant';
    font-weight: 100;
    src: local('Assistant-ExtraLight'), url(./resources/fonts/Assistant-ExtraLight.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Assistant';
    font-weight: 700;
    src: local('Assistant-SemiBold'), url(./resources/fonts/Assistant-SemiBold.ttf) format('truetype');
  }