.bottom-overlay {
    display: flex;
    flex-direction: column;
    //background-color: #5e5d5d;
    z-index: 3;

    &.hidden-overlay {
        height: 0;
        transition: height 400ms; 
    }
    &.small-overlay {
        height: 60px;
        transition: height 400ms; 
    }
    &.full-overlay {
        height: 100%;
        transition: height 400ms; 

        .close-button {
            margin-left: auto;
            margin-right: 10px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 28px;

            &:hover {
                cursor: pointer;
                path {
                    fill: #626262; 
                }    
            }

            path {
                fill: white;
                stroke: #626262;
                stroke-width: 16px;
            }

            .text {
                padding-inline: 5px;
                max-width: 70px;
                font-size: 14px;
                font-family: "BenchNine";
                text-transform: uppercase;
                margin-top: -1px;
                overflow: hidden;
                text-align: center;
                height: 20px;
            }
        }

        .accordion {
            .card {
                border: 0;
                margin-bottom: 10px;
                border-radius: 3px;
            }
            .card-header {
                padding: 0;
                height: 160px;
                margin-bottom: -2px;

                .content-area {
                    margin: 0;
                }
            }

            .card-body {
                padding: 0;
                .tuple {
                    display: flex;
                    max-width: 200px;

                    .tuple-title {
                        color: #7a7a7a;
                        font-size: 13px;
                    }
                    .tuple-value {
                        flex-grow: 1;
                        margin-right: 0;
                        text-align: end;
                    }
                }
            }
        }
    }


    &.hide {
        
        top: calc(-100% + 50px);
        transition: top 400ms;
    }


    

    &.small-overlay {
        .content-area {
            position: relative;
            height: 100%;
            background-color: #5e5d5d;

            .overlay-view {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                pointer-events: none;
                .power {
                    font-family: "BenchNine";
                    font-size: 40px;
                    color: #b2ddff; //53d36f
                    font-weight: 800;
                    position: relative;
                    left: 5px;

                    span {
                        font-size: 15px;
                        color: #d7d7d7;
                    }
                }
            }

            .flex-view {
                display: flex;
                height: 100%;
                align-items: center;
                flex-grow: 1;
                background-color: #5e5d5d;
                color: white;
                position: relative;
                overflow: auto;

                .ep-icon {
                    margin-right: 4px;
                    padding: 5px;
                    fill: #b2ddff;
                    stroke: #545353;
                    //animation: pulse 3s infinite;
                }

                .outlet-name-group {
                    max-width: 110px;
                    font-size: 13px;
                    min-width: 33%;

                    .outlet-name {
                        line-height: 14px;
                        font-weight: 700;
                        color: #f9f9f9;
                    }
                    .provider-name {
                        opacity: .7;
                        margin-top: 4px;
                    }
                }

                .charge-info {
                    font-size: 13px;
                    color: #f9f9f9;
                    margin-left: auto;
                    margin-right: 10px;

                    svg {
                        opacity: .5;
                    }

                    div {
                        min-width: 85px;
                    }
                    .energy {
                        margin-top: 4px;
                        padding-left: 3px;
                        span {
                            font-family: "BenchNine";
                        }
                    }
                }

                .stop-button {
                    
                    margin-right: 16px;
                    opacity: 0.6;
                    svg {
			color: red; //5f5e5d
                        width: 30px;
                        height: 30px;
			border: solid;
			border-color: white;
			border-radius: 0.5em;
			border-width: 0.3em;
			background-color:red;
                    }

                    &:hover {
                        opacity: 1;
                        cursor: pointer;
                    }
                }

                .show-all-button {
                    margin-right: 6px;
                    background-color: white;
                    opacity: 0.6;
                    margin-left: auto;
                    width: 75px;
                    height: 30px;
                    border-radius: 15px;
                    font-family: "BenchNine";
                    font-size: 11px;
                    text-transform: uppercase;
                    color: #333;
                    line-height: 12px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &.full-overlay {

        .accordion {
            padding-left: 10px;
            padding-right: 10px;
        }

        .content-area {
            position: relative;
            height: 100%;
            background-color: #5e5d5d;

            .second-row {
                font-size: 13px;
                background-color: #5e5d5d;
                color: white;
                padding-left: 10px;
                padding-right: 10px;

                .outlet-name {
                    font-size: 15px;
                    font-weight: 700;
                    color: #f9f9f9;
                }
                .provider-name {
                    opacity: .7;
                }

            }

            .readings-row, .details-row {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                color: white;

                .readings-item {
                    min-width: 75px;
                    .reading-title {
                        text-transform: uppercase;
                        font-size: 10px;
                        opacity: .7;
                    }
                    .reading-value {
                        font-size: 15px;
                    }
                }
            }

            .details-row {
                margin-top:0;
                padding: 20px 10px;
            }

            .separator {
                width: 97%;
                height: 1px;
                background-color: #37ce5854;
                margin-left: auto;
                margin-right: auto;
            }

            .top-row {
                display: flex;
                height: 60px;
                align-items: center;
                flex-grow: 1;
                background-color: #5e5d5d;
                color: white;
                position: relative;
                overflow: auto;

                .ep-icon {
                    margin-right: 4px;
                    padding: 5px;
                    fill: #b2ddff;
                    stroke: #545353;
                }

                .power {
                    font-family: "BenchNine";
                    font-size: 40px;
                    color: #b2ddff;
                    font-weight: 800;
                    position: relative;
                    left: 5px;

                    span {
                        font-size: 15px;
                        color: #d7d7d7;
                    }
                }


                .charge-info {
                    font-size: 13px;
                    color: #f9f9f9;
                    margin-left: auto;

                    svg {
                        opacity: .5;
                    }

                    div {
                        min-width: 85px;
                    }
                    .energy {
                        margin-top: 4px;
                        padding-left: 3px;
                        span {
                            font-family: "BenchNine";
                        }
                    }
                }

                .stop-button {
                    display: flex;
                    align-items: center;
                    margin-right: 6px;
                    margin-left: auto;
                    width: 75px;
                    height: 30px;
                    border-radius: 15px;
                    background-color: white;
                    opacity: 0.6;
                    .button-dot {
                        width: 11px;
                        height: 11px;
                        background-color: red; //5f5e5d
                        margin-left: 9px;
                        border-radius: 1px;
                    }
                    .button-text {
                        font-family: "BenchNine";
                        font-size: 11px;
                        text-transform: uppercase;
                        color: #333;
                        line-height: 12px;
                        font-weight: bold;
                        margin-left: 6px;
                    }
                    &:hover {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    
    @keyframes pulse {
        0% {
          fill: blue;
        }
        50% {
          fill: #747de847;
        }
        100% {
            fill: blue;
        }
      }
    
}

@media screen and (max-width: 500px) {
    .small-overlay {
        .outlet-name-group {
            min-width: 23% !important; 
        }

        .stop-button {
            margin-right: 6px !important;
        }

        .charge-info {
            margin-right: 0 !important;
        }
    }
    
  }


.end-session-info-modal {
    z-index: 1049;

    .modal-content {
        margin-left: 40px;
        margin-right: 40px;
    }
}  
