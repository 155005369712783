.uninserted-outlet-view {
    .header-text {
        text-align: center;
        position: relative;
        top: -170px;
        font-size: 25px;
    }

    .animation {
        width: 100%;
        margin-top: 50px;
        //background-image: url(../resources/insert_plug.gif);
        background-image: url(../resources/insert_plug.gif);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 200px;
        clip-path: circle(90px at center);
    }

    .countdown {
        position: relative;
        top: -190px;
    }

    .timeout-info {
        position: relative;
        top: -100px;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        max-width: 350px;
        text-align: center;
        transition: opacity 0.5s;
        opacity: 1;
        &.hide {
            opacity: 0;
        }

        .timeout-button {
            margin-top: 30px;
            background: var(--action-color);
            color: white;
            text-transform: uppercase;
            padding: 11px 0;
            font-size: 22px;
            font-weight: bold;

            &:hover {
                cursor: pointer;
                background-color: var(--action-color-darken);
            }
    
            &:active {
                background-color: var(--action-color-darken) !important;
            }
        }
    }
}