@import "common.scss";



.map-view {

    .map-loading {
        display: flex;
        height: 100vh;
        align-items: center;
        font-size: 35px;
        .loading-info {
            margin-left: auto;
            margin-right: auto;

            svg {
                opacity: .4;
                margin-right: 10px;
                //animation: pulsebg 2s infinite;
                margin-right: 10px;
                border-radius: 26px;
                animation: pulser 2s infinite;

                
            }
            span {
                opacity: .6;
            }
        }

        @keyframes pulser {
            0% {
              opacity: .2;
              transform: scale(.8);
            }
            50% {
                opacity: .6;
                transform: scale(1);
            }
            100% {
                opacity: .2;
                transform: scale(.8);
            }
        }   
        
    }

    .content {
        width: 100%;
        height: calc(100vh - 50px);
        overflow-y: hidden;

        .filter-panel {
            width: 100%;
            height: 0;
            transition: height .3s ease-in-out;
            border-bottom: 1px solid #aaa;
            padding-left: 10px;

            h3 {
                padding-top: 10px;
                font-size: 14px;
                text-transform: uppercase;
            }

            .settings-prop {
                margin-top: 9px;
                display: flex;
                .label {
                    color: #555;
                    display: inline-block;
                    margin-left: 10px;
                    font-size: 14px;
                }
            }

            .info-text {
                color: #555;
                font-size: 14px;
                margin-top: 8px;
            }

            &.show {
                height: 105px;
            }

            .help-text-button {
                margin-left: 10px;
            }
        }

        .map-section {
            overflow-y: hidden;
            position: relative;
            width: 100%;
            height: calc(100vh - 50px);
            transition: height .3s ease-in-out;
            &.shrunken {
                height: calc(100vh - 150px); //shrunken with height of filter panel
            }
        }

        .unreg-info-text {
            font-size: 24px;
            text-align: center;
            padding: 10px;
            padding-top: 30%;
        }

        .square-button {
            position: absolute;     
            background-color: white;
            border-radius: 2px;
            width: 40px;
            height: 40px;
            display: flex;
            padding: 9px;
            color: #666666;
            box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);

            &.center {
                top: 10px;
                left: 10px;
            }

            &.filter {
                top: 10px;
                left: 60px;

                &.selected {
                    background-color: var(--action-color);
                    color: #fbfbfb;
                    box-shadow: none;
                }
            }
            
            &.center.pulsate {
                animation: pulsebg 2s infinite;
            }

            &:hover {
                cursor: pointer;
                color: #333;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }

        @keyframes pulsebg {
            0% {
              color: #666666;
            }
            50% {
                color: #37ce58;
            }
            100% {
                color: #666666;
            }
          }
        
          


        
    }
}


.cluster-marker {
    color: #333333;
    background: #ffffff;
    border-radius: 50%;
    font-size: 20px;
    padding: 10px;
    border: 1px solid var(--action-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .location-marker {
    background: none;
    border: none;
    &:focus {outline:0 !important;}

    
  }
  
  .location-marker img {
    width: 25px;
  }

  .button-bg {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    //border: 2px solid var(--action-color);
    border: 2px solid white;
    padding: 3px;
    background-color: #000;
    transform: translate(-50%,-50%);
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);

    &.z11, &.z12 {
        width: 15px;
        height: 15px;
    }
    &.z13, &.z14 {
        width: 20px;
        height: 20px;
    }
    &.z15 {
        width: 25px;
        height: 25px;
    }
    &.z16, &.z17, &.z18, &.z19, &.z20, &.z21, &.z22 {
        width: 30px;
        height: 30px;
    }

    &.with-parking-map {
        border: 3px solid white;
    }

    &.with-restriction {
        border-radius: 20%;
    }

    &.status_STATUS_FREE {
        background-color: #129077;
    }
    &.status_STATUS_CROWDED {
        background-color: #FFD908;
    }
    &.status_STATUS_FULL {
        background-color: #F14B53;
    }

    .epspot-map-icon {
        width: 100%;
        height: 100%;
        //background-image: url(../resources/logoSqLowRes.png);
        //background-size: cover;
    }
        
}

.user-marker {
    background-color: #4286f44b;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    transform: translate(-50%,-50%);

    div {
        border: 1px solid white;
        background-color: #4285F4;
        margin-left: auto;
        margin-right: auto;
        border-radius: 6px;
        width: 12px;
        height: 12px;
    }
}

