@import "common.scss";

.slider-value-row {

    

    .primary-info {

    .charge-type-toggle {
        width: 80px;
        height: 30px;
        position: absolute;
        bottom: 15px;
        left: 5%;

        .btn {
            background-color: #ffffff;
            border-color: #3a8038;
            -webkit-box-shadow: inset 0px 0px 5px 2px #cde8cc;
            -moz-box-shadow: inset 0px 0px 5px 2px #cde8cc;
            box-shadow: inset 0px 0px 5px 2px #cde8cc;
        }

        .btn:first-child {
            
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
        }
        .btn:last-child {
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
        }

        .btn:active, .btn.active {
            background-color: #459c44;
            -webkit-box-shadow: inset 0px 0px 3px 1px #377536;
            -moz-box-shadow: inset 0px 0px 3px 1px #377536;
            box-shadow: inset 0px 0px 3px 1px #377536;
            z-index: 0;

            svg {
                stroke: #ffffffb3;
                stroke-width: 30px;
            }
        }

        .btn:focus {
            outline: none !important;
            box-shadow: none !important;
          }

        svg {
            height: 100%;
            position: absolute;
            top: 0;
            height: 100%;
            left: -12%;
            fill: transparent;
            stroke: var(--action-color);
            color: transparent;
            stroke-width: 20px;
        }

        fa-clock {
            width: 50%;
        }

        
    }
    }



    .slider-value-group { 
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
        text-align: center;
        position: relative;

        .slider-header {
            font-size: 30px;
        }

        .primary-info {
            position: relative;
            .xfa-clock, .xbattery-icon {
                stroke-width: 20px;
                font-size: 30px;
                position: absolute;
                bottom: 15px;
                left: calc(50% - 110px);
            }
            .xfa-clock {
                color: transparent;
                stroke: var(--action-color);
                stroke-width: 20px;
                font-size: 30px;
                position: absolute;
                bottom: 15px;
                left: calc(50% - 110px);
            }
            .xbattery-icon {
                width: 50px;
                height: 50px;
                fill: transparent;
                stroke: var(--action-color);
                stroke-width: 15px;
                font-size: 30px;
                position: absolute;
                bottom: 5px;
                left: calc(50% - 120px);
            }
            .duration {
                display: inline-block;
                position: relative;
                left: 10px;
                color: var(--primary-info-color);
                font-weight: normal;
                font-size: 44px;
                font-family: 'Assistant';

                span:nth-child(1) {
                }
                span:nth-child(2) {
                    font-size: 35px;
                    width: 18px;
                    text-align: end;
                }
                span:nth-child(3) {
                    width: 48px;
                    display: inline-block;
                    text-align: end;
                }
                span:nth-child(4) {
                    width: 16px;
                    font-size: 35px;
                }
            }
        }
        

        .secondary-info {
            color: #999;
            
            .separator {
                display: inline;
            }
            .power {
                display: inline-block;
            }
        }
      }  
      
      .rangeslider {
        margin-left: 50px;
        margin-right: 50px;
        margin-top: 30px;
        margin-bottom: 40px;
        
        .rangeslider__fill {
            background-color: var(--action-color);
            opacity: 0.4;
        }

        .rangeslider__handle {
            width: 40px;
            height: 40px;
            //background: rgb(44,102,44);
            //background: linear-gradient(0deg, #3c8a3c 0%, #4cad4c 71%, #7bb342 100%);
            background: var(--action-color);
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 -1px 3px rgba(0, 0, 0, 0.2);
            border: none;
            &:after {
                display: none;
            }
            &:focus {
                outline:none;
            }

        }
    }


    
}

.cost {
    position: relative;
    top: -20px;
    display: flex;
    color: var(--primary-info-color);
    font-weight: normal;
    font-size: 44px;
    width: 180px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Assistant';
    justify-content: center;
    align-items: baseline;
    div {
        display: inline-block;
        &:first-child {
            
        }
        &:nth-child(2) {
            font-size: 35px;
        }
        
    }
    
    
}


.centered-toggle-button {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
    align-items: center;

    &.allowed-disconnect {
        margin-bottom: 30px;
    }

    .left-side {
        flex-grow: 1;

        .shortened-line {
            background-color: #cccccc;
            height: 1px;
            width: calc(100% - 50px);
        }

        
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .right-side {
        flex-grow: 1;

        background-color: #cccccc;
        height: 1px;
        flex-grow: 1;
        margin-left: 20px;
        margin-right: 20px;
    }

    &.off {
        
        .left-side, .right-side {
            visibility: hidden;
        }
    }

    .react-toggle {
        position: absolute;
        right: calc( 50% + 60px );
    }

    label {
        text-transform: uppercase;
        color: #999;
        font-family: 'BenchNine';
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

.departure-time-section, .power-amount-section {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    height: 0;
    overflow: hidden;

    &.dts-enter, &.pas-enter {
        height: 0;
      }
      &.dts-enter-active {
        height: 40px;
        transition: height 200ms;
      }
      &.dts-enter-done {
        height: 40px;
        overflow: visible;
      }
      &.dts-exit {
        height: 40px;
      }

      &.pas-enter-active {
        height: 220px;
        transition: height 200ms;
      }
      &.pas-enter-done {
        height: 220px;
        overflow: visible;
      }
      &.pas-exit {
        height: 220px;
      }

      &.dts-exit-active, &.pas-exit-active {
        height: 0;
        transition: height 200ms;
      }

      .dropdown {
        display: inline-block;
        margin-right: 5px;
        }

      .react-datepicker-wrapper {
        position: absolute;
        right: 50%;
        visibility: hidden;
    }

    .rc-time-picker-clear {
        display: none;
    }
    

    input[type=text] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        text-align: center;
        width: 120px;
        height: 35px;
        border: 1px solid #d9d9d9;
        color: #666;
        font-size: 16px;
        border-radius: 3px;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    
}

.reg-input {
    display: flex;
    height: 0;
    overflow: hidden;

    &.ris-enter {
        height: 0;
      }

      &.ris-enter-active {
        height: 60px;
        transition: height 200ms;
      }
      &.ris-enter-done {
        height: 60px;
        overflow: visible;
      }
      &.ris-exit {
        height: 60px;
      }

      &.ris-exit-active {
        height: 0;
        transition: height 200ms;
      }

    .reg-no-text {
        color: #333;
        font-family: sans-serif;
    }  

    .dropdown {
        margin-left: auto;
        margin-right: auto;
        width: 240px;
        

        .dropdown-toggle {
            padding: 0;
            width: 100%;
        }

        .dropdown-menu {
            width: 240px;

            .dropdown-item {
                padding: 5px;
            }
        }

        .vehicle-list-item {
            width: 100%;
            display: flex;
            align-items: baseline;

            .second {
                font-size: 11px;
                margin-left: auto;
            }
        }
    }

    #bg-nested-dropdown {
        background-color: #ffffff;
        border: 1px solid #999;
        font-size: 16px;
        text-transform: uppercase;
        color: #999;
        font-family: "BenchNine";
        padding-top: 5px;
        padding-bottom: 5px;

        &::after {
            content: none;
        }

        .button-title {
            width: 110px;
            white-space: break-spaces;
            height: 40px;
            line-height: 20px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            
            &::after {
                content: none;
            }
        }
    } 
}


.reg-no-input {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;

        .dropdown-toggle {
            padding: 0;
        }
        .dropdown-menu {
            bottom: 56px !important;
            top: auto !important;
            left: auto !important;
            right: 0 !important;
            transform: none !important;
            font-size: 14px;
        }
        #bg-nested-dropdown {
            background-color: #ffffff;
            border: 2px solid #459d44;
            color: #459d44;
            font-size: 20px;

            &::after {
                content: none;
            }

            .button-title {
                width: 110px;
                white-space: break-spaces;
                height: 40px;
                line-height: 20px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 700;
                
                &::after {
                    content: none;
                }
            }
        } 
        .no-card-info-text {
            width: 150px;
            white-space: normal;
            padding-right: 0;
            padding-top: 0;
            font-size: 12px;
        }   

}



.time-picker-popup {
    &.rc-time-picker-panel {
        width: 240px;

    }
    .rc-time-picker-panel-input-wrap {
        display: none;
    }
    
    &.rc-time-picker-panel-narrow {
        max-width: 240px;
    }

    .rc-time-picker-panel-select:first-child {
        width: 130px;
    }
    
    .rc-time-picker-panel-select {
        width: 105px;
        max-height: 240px;
        font-size: 30px;

        li:focus, li:active:focus {
            outline: none;
            box-shadow: none;
          }
    }

    .rc-time-picker-panel-select li {
        padding: 20px 0px 0px 30px;
        height: 60px;
    }
    li.rc-time-picker-panel-select-option-selected {
        background: var(--action-color);
        color: white;
        font-weight: bold;
    }

}

/* Disable hover styles on ios */
.enable-hover-effects {
    .rc-time-picker-panel-select li:hover {
        background: #d9d9d9;
        color: #212529;
        font-weight: normal;
    }
    
    li.rc-time-picker-panel-select-option-selected:hover {
        background: var(--action-color);
        color: white;
        font-weight: bold;
    }
}

.disable-hover-effects {
    .rc-time-picker-panel-select li:hover {
        background: #ffffff;
    }
    
    li.rc-time-picker-panel-select-option-selected:hover {
        background: var(--action-color);
    }

    .start-charging-view .slider-value-row .charge-type-btn:hover {
        background-color: white;
                color: #999999;
    }
}

/* ios 1px midalignment fix */ 
.ios-platform {
    .departure-time-section {
        .dropdown {
            input {
                position: relative;
                top: -1px;
            }

        }
    }
}