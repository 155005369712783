@import "common.scss";
.registration-view {

    .content-container {
        height: calc(100vh - 50px);
    }

    .step-container {
        height: 300vh;
        overflow: hidden;
        //scroll-behavior: smooth;
        
    }

    .page-1, .page-2, .page-3 {
        height: calc(100vh - 150px); //full height - header height - bottom space height
        //padding-top: 50px;
        position: relative;
    }

    .page-container {
        height: 100%;
        overflow: auto;
        position: relative;
    }

    .form-label {
        font-family: 'Assistant';
        font-size: 13px;
        text-transform: uppercase;
        color: #666;
        margin-bottom: 0;
    }

    

    .input-feedback {
        color: rgb(235, 54, 54);
      }

    .button-heading {
        .edit-button-with-label {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            align-items: flex-end;

            span {
                color: #ffffffc7;
                margin-bottom: 3px;
                white-space: nowrap;
            }
        }
    }
    

    .bottom-center {
        //position: absolute;
        //bottom: 30px;
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .next-page-button {
            @include rounded-primary-button;
        }
    }

    .top-center {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 10px;
        .prev-page-button {
            @include rounded-secondary-button;
        }
    } 
    
    

    .welcome-text {
        font-size: 13px;
        margin-top: 20px;
        margin-bottom: 20px;
     
    }

    .link-to-activation-input {
        color: var(--action-color);
        &:hover {
            text-decoration: underline;
        }
    }

    .name-form {
        margin-top: 20px;
        display: flex;
        .form-group:first-child{
            flex-basis: 40%;
        }
        .form-group:last-child{
            margin-left: 5px;
            flex-basis: 60%;
        }
    }

    .sub-title {
        font-size: 12px;
        margin-bottom: 10px;
        color: #666666;
    }

    .terms-header {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .confirm-cancel-button-row {
        margin-top: 60px;
        .registration-button {  
            @include confirm-button;
        }
    
        .cancel-button {
            @include cancel-button;
        }
    }

    .showPasswordIcon {
        position: absolute;
        right: 0;
        margin-right: 10px; 
        margin-top:2%;
        font-size: 20px;
        z-index: 3;
      }

    
}


.account-ready-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
        margin-top: 20px;
        font-size: 25px;
        color: var(--action-color);
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
    }

    button {
        @include rounded-primary-button;
    }
}

.error-text {
    text-align: center;

    button {
        @include rounded-primary-button;
    }
}



.code-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .welcome-text {
        margin-bottom: 30px;        
    }

    .cancel-change-pw-link {
        color: var(--action-color);
        
        &:hover {
            text-decoration: underline;
        }
    }

    .react-code-input2 {
        display: flex !important;
        width: 100%;
        justify-content: center;
        input {
            font-family: monospace;
            border-radius: 6px;
            border: 1px solid lightgrey;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
            margin: 4px;
            padding-left: 4px;
            width: 100%;
            max-width: 50px;
            height: 50px;
            text-align: center;
            font-size: 32px;
            box-sizing: border-box;
            color: black;
            background-color: white;

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }

            /* Firefox */
            &[type=number] {
            -moz-appearance: textfield;
            }
        }
    }

    .activation-call-spinner {
        color: var(--action-color);
        position: relative;
        top: -45px;
    }

}

