@import "common.scss";

.profile-view {
    height: 100%;

    

    .vehicle-list {
        
        .license-plate {
            margin-bottom: 10px;
            margin-top: 5px;
            .vehicle-name {
                font-size: 12px;
            }

            .removable-plate {
                position: relative;
                display: flex;
                
                align-items: center;
                
        
                button {
                    margin-left: 30px;
                    border-radius: 30px;
                    background-color: transparent;
                    color: #acacac;
                    border-color: transparent;
                    
                    &:hover {
                        background-color: #e5e5e5;
                    }
                    &:focus {
                        outline: 0 !important;
                        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
                      }
                }
            }
    
            .reg-no {
                margin-right: 5px;
                text-align: right;
                width: 122px;
                height: 40px;
                font-size: 23px;
                padding-top: 3px;
                padding-right: 8px;
                text-transform: uppercase;
                background-size: contain;
                background-repeat: no-repeat;
                background-image:url('../resources/reg-template.png');
        
                span:last-child {
                    margin-left: 5px;   
                }
            }
        }
    }

    

    
    .navx {
        margin-left: -5%;
        margin-right: -5%;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 20px;
        margin-bottom: 30px;
        list-style: none;
        border-bottom: 1px solid #ddd;

        .nav-item {

            .nav-link {
                color: #555;
                padding: 0;
                padding-bottom: 5px;
                &.active {
                    color: #333;
                    font-weight: bold;
                    border-bottom: 2px solid #333;
                }
            }
        }
    }

    .month-transaction-item {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 10px;
        padding-bottom: 10px;

        .card-header {
            background-color: transparent;
            border-bottom: none;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }

        &.card {
            border: none;

            .card-body {
                padding: 0;
            }

            

            .transaction-list-details {
                table {
                    width: 100%;
                    font-size: 13px;
    
                    td:nth-child(2), th:nth-child(2), 
                    td:nth-child(3), th:nth-child(3), 
                    td:nth-child(4), th:nth-child(4) { 
                        text-align: end; }

                    .balance-col {
                        min-width: 70px;
                    }
                    .empty-row {
                        height: 1px;
                        background-color: #f8f8f8;
                    }

                    .click-target {
                        text-decoration: underline;
                    }

                    thead {
                        line-height: 30px;
                    }

                    /* tbody:before {
                        content: '';
                        display: block;
                        height: 20px;
                      } */

                    /* tbody {
                        &:nth-child(odd) {
                            background-color: #f00;
                        }
                    } 
                    tbody {
                        &:nth-child(even) {
                            background-color: #f00;
                        }
                    }  */ 

                }
            }
        }

        &:nth-child(odd) {
            background-color: #fdfdfd;
        }
        &:nth-child(even) {
        }

        .top-row {
            display: flex;
            .month {
                font-size: 14px;
                font-weight: bold;
            }
            .balance {
                font-weight: 500;
                margin-left: auto;
            }
        }

        .body-section {
            display: flex;
            flex-direction: column;
            .section-item {
                width: 150px;
                display: flex;
                justify-content: space-between;
                .title {
                    font-family: "Assistant";
                    font-size: 13px;
                    color: #666;
                }
                .value {
                    font-size: 13px;
                }
            }
        }

        
        
    }

    

    .logout-button {
        margin-top: auto;
        margin-bottom: 10px;
        
        @include rounded-secondary-button;
    }

    .saved-payment-card {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 8px;
        background-color: #f8f8f8;
        border-bottom: 1px solid #e4e4e4;
        border: 1px solid #e4e4e4;
        padding: 6px;
        img {
            height: 30px;
        }
        .details {
            display: flex;
            flex-direction: column;
            margin-left: 5px;
            .last4 {
                
                .dots {
                    font-size: 30px;
                    line-height: 14px;
                    position: relative;
                    top: -3px;
                }
            }
            .valid {
                font-size: 9px;
                font-style: italic;
            }
        }
        .primary-card-button {
            padding: 5px 12px;
            margin-left: 5px;
            color:#dadada;

            &:hover {
                cursor: pointer;
            }

            &.active {
                color: var(--action-color);
            }
        }
        .remove-card-button {
            margin-left: auto;
            border-radius: 30px;
            background-color: #f8f8f8;
            color: #acacac;
            border-color: #acacac;
            
            &:hover {
                background-color: #e5e5e5;
            }
            &:focus {
                outline: 0 !important;
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
              }
        }
        
    }



    .ovale-button {
        @include rounded-primary-button;

        &.add-card {
            margin-top: 15px;
            margin-left: auto;
            display: block;
            @include rounded-secondary-button;
        }

        &.topup {
            &:focus {
                box-shadow: none;
            }
        }
    }
}

//prop-touples are used outside of profileView too

.prop-touple-action {
    display: flex;
    align-items: center;

    .prop-touple {
        flex-grow: 1;
    }
}

.prop-touple {
    margin-top: 10px;
    margin-bottom: 10px;

    .superadmin-label {
        width: fit-content;
        background-color: #eeff00;
        font-family: "BenchNine";
        text-transform: uppercase;
        font-weight: bold;
        font-size: 24px;
        padding-left: 5px;
        padding-right: 5px;
    }

    &.balance-prop {
        margin-bottom: 30px;
    }

    .prop-touple-label {
        font-family: 'Assistant';
        font-size: 13px;
        text-transform: uppercase;
        color: #666;
    }
    .prop-touple-value {
        font-size: 16px;

        &.balance {
            font-weight: 600;
            font-size: 35px;
        }

        .membership {
            margin-bottom: 8px;

            .membership-name {
                font-weight: 500;
            }

            .accesses {
                color: blue;

                .acc-label {
                    display: inline;
                    font-family: "Assistant";
                    font-size: 13px;
                    text-transform: uppercase;
                    color: #666;
                }
                .acc-name {
                    display: inline;
                    background-color: #555;
                    color: white;
                    padding: 1px 6px;
                    font-size: 13px;
                    font-weight: 500;
                    border-radius: 3px;
                    margin-left: 5px;
                }
            }

            
        }
        
    }

    .change-pw-link {
        color: var(--action-color);
        margin-left: 30px;
        
        &:hover {
            text-decoration: underline;
        }
    }

    .prop-touple-action-value {
        display: flex;
        align-items: center;

        .prop-touple-value {
            flex-grow: 1;
        }

        .prop-touple-action {
        }
    }
}