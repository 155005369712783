.outlet-base-info2 {
    

    
    .bg-plate {
        position: relative;
        background-color: #ededed;
        display: flex;
        align-items: center;
        height: 70px;
        border-bottom: 1px solid #dddddd;

        .center-group {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            .top-row {
                display: flex;
                margin-top: 4px;
                align-items: baseline;
                flex-grow: 1;

                .ob-name {
                    margin-left: 3%;
                    font-size: 15px;
                    font-weight: bold;
                }

                .ob-max-charge {
                    margin-left: auto;
                    margin-right: 3%;

                    svg {
                        font-size: 16px;
                        margin-right: 3px;
                        color: #cfcfcf;
                    }
                }
            }
            .fee-row {
                margin-left: 3%;
                margin-bottom: 2px;
                font-size: 12px;
                color: #666;
                svg {
                    font-size: 14px;
                    margin-right: 3px;
                    color: #cfcfcf;
                }
            }
            .details-button-click-area {
                position: absolute;
                bottom: 0;
                right: 0;
                top: 0;
                width: 30%;
            }

            .details-button {
                position: absolute;
                margin-right: 3%;
                bottom: 0;
                right: 0;
            }
        }

        h1 {
            text-align: center;
            font-size: 16px;
            font-family: 'BenchNine';
            font-weight: bold;
            padding-top: 10px;
            color: white;
            padding-bottom: 2px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            margin-bottom: 0;
            background-color: var(--primary-info-color);
        }

        .svg-border-btn {
            font-size: 20px;
            display: inline-block;
            color: var(--action-color);
            fill-opacity: 0.4;

            &:hover {
                fill-opacity: 1;
            }

            path {
                stroke: var(--action-color);
                stroke-width: 20px;
            }
        }

        .outlet-disconnect-button {
            width: 9px;
            font-size: 22px;
            display: inline-block;
            color: var(--action-color);
            &:hover {
                font-size: 24px;
            }
        }

        .x-button {
            margin-left: 10px;
            margin-right: 10px;
            
        }
        .i-button {
            position: absolute;
            right: 6px;
            bottom: 2px;
        }
    }
    
    .ob-name-id-group {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 10px 5px;
        
        .ob-name {
            min-width: 220px;
            max-width: 300px;
            font-size: 22px;
            overflow: hidden;
            white-space: nowrap;
        }
        .ob-id {
            color: #999;
        }
    }
    
}

.outlet-base-info {
    display: flex;
    margin-top: 30px;

    


    .ob-left {
        flex-grow: 1;
    }
    .ob-right {
        flex-grow: 1;
        position: relative;
        text-transform: uppercase;
        font-size: 14px;
        color: #999;

        .v-separator {
            display: inline-block;
            background-color: #cccccc;
            width: 1px;
            height: 90%;
            position: absolute;
            left: 11px;
            top: 10px;
        }

        .more-info { 
            position: absolute;
            left: 20px;
            top: 61px;
        }
         .swap-outlet {
            position: absolute;
            left: 20px;
            top: 8px;
         }
    }
    
    .ob-name-id-group {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        
        .ob-name {
            font-size: 35px;
        }
        .ob-id {
            color: #999;
        }
    }
    
}
