.settings-view {

    .content {
        
        margin-top: 50px;
        padding-left: 10%;

        .settings-prop {
            //margin-left: 10%;
            margin-top: 30px;
            display: flex;
            .label {
                color: #777;
                text-transform: uppercase;
                display: inline-block;
                width: 170px;
            }
        }

        .dev-panel {
            margin-top: 50px;
            background-color: white;
            margin-right: 10%;
            padding: 10px;
            border: 1px solid blue;

            h3 {
                font-size: 16px;
            }

            h4 {
                font-size: 14px;
                margin-top: 20px;
            }

            .label {
                color: #777;
                text-transform: uppercase;
                font-size: 14px;
            }
            .value {
                font-size: 11px;
            }
        }
    }
}