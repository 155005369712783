@import "common.scss";

.card-payment-view {
    .content {
        min-height: 620px;
        padding: 30px;

        h1 {
            font-size: 24px;
            margin-bottom: 20px;
        }

        .swish-qr {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            text-align: center;

            img {
                width: 70%;
                margin-top: 40px;
            }

            .cancel-button {
                margin-top: 60px;
                @include cancel-button;
            }
        }

        form {
            label {
                display: block;
                display: block;
                color: #868686;
                font-size: 12px;
                margin-top: 20px;

                &.cvc, &.expiry-date {
                    display: inline-block;
                    width: 30%;
                    margin-right: 20px;
                    margin-top: 10px;
                }

                .StripeElement {
                    /*display: block;
                    margin: 10px 0 20px 0;
                    max-width: 500px;
                    padding: 10px 14px;
                    font-size: 1em;
                    font-family: "Source Code Pro", monospace;
                    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
                    border: 0;
                    outline: 0;
                    border-radius: 4px;
                    background: white;*/

                    iframe {
                        height: 24px !important;
                    }
                }
            
                

                
            }

            .error-text {
                color: red;
                display: inline-block;
                text-align: left;
                font-size: 12px;
                font-weight: bold;
                margin-top: 20px;
                margin-bottom: 6px;
                height: 19px;
            }

            .ok-button {
                @include confirm-button;
            }

            .cancel-button {
                @include cancel-button;
                margin-top: 10px;
            }

        }
        
    }
}
