@import "common.scss";

$midnight: #2c3e50;
$clouds: #ecf0f1;

.faq-view {
    .content {
        width: 90%;
        min-width: 310px;
        margin-left: auto;
        margin-right: auto;
        min-height: 550px;

        h1 {
            border-top: 1px solid #dddddd;
            margin-top: 15px;
            margin-left: 10px;
            padding-top: 15px;
            font-size: 20px;

            &:first-child {
                border-top: none;
            }
        }
    }



    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    label {
        margin-bottom: 0;
    }

    .tabs {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
    }

    .tab {
        width: 100%;
        //color: white;
        overflow: hidden;

        &-label {
            display: flex;
            color: #555;
            justify-content: space-between;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            cursor: pointer;
            align-items: center;

            /* Icon */
            &:hover {
                color: #000;

                &::after {
                    color: #000;
                    font-weight: bold;
                }
            }

            &::after {
                content: "\276F";
                text-align: center;
                transition: all .2s;
                margin-left: 10px;
                margin-right: 10px;

            }
        }

        &-content {
            max-height: 0;
            padding: 0 10px;
            margin-top: 5px;
            background: white;
            transition: all 0.35s;
            font-size: 14px;
        }

        &-close {
            display: flex;
            justify-content: flex-end;
            padding: 1em;
            background: $midnight;
            cursor: pointer;

            &:hover {
                color: #000;
            }
        }
    }

    .faq {
        width: 100%;
        overflow: hidden;

        &-label {
            font-weight: bold;
            display: flex;
            color: #555;
            justify-content: space-between;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            cursor: pointer;
            align-items: center;

            /* Icon */
            &:hover {
                color: #000;

                &::after {
                    color: #000;
                    font-weight: bold;
                }
            }

            &::after {
                content: "\276F";
                text-align: center;
                transition: all .35s;
                margin-left: 10px;
                margin-right: 10px;

            }
        }

        &-content {
            max-height: 0;
            padding: 0 10px;
            margin-top: 5px;
            background: white;
            transition: all 0.2s;
            font-size: 14px;
        }

        &-close {
            display: flex;
            justify-content: flex-end;
            padding: 1em;
            background: $midnight;
            cursor: pointer;

            &:hover {
                color: #000;
            }
        }
    }

    // :checked
    input:checked {
        +.tab-label {
            background: #efefef;
            color: #333;

            &::after {
                transform: rotate(90deg);
            }
        }

        ~.tab-content {
            max-height: 300vh;
            padding-top: 10px;
            user-select: text;
        }

        +.faq-label {
            color: #333;

            &::after {
                transform: rotate(90deg);
            }
        }

        ~.faq-content {
            max-height: 300vh;
            padding-top: 10px;
            user-select: text;
        }
    }

}