@import "common.scss";

.heater-cost {
    position: relative;
    margin-top: 10px;
    display: flex;
    color: var(--primary-info-color);
    font-weight: normal;
    font-size: 44px;
    width: 180px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Assistant';
    justify-content: center;
    align-items: baseline;
    div {
        display: inline-block;
        &:first-child {      
        }
        &:nth-child(2) {
            font-size: 35px;
        } 
    }

    .help-text-button {
        position: absolute;
        right: 0;
        top: -20px;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}

.heater-cost-info {
    width: 130px;
    margin-top: -10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 12px;
    color: #555;
}

.heater-time-header {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
    align-items: center;

    .left-side, .right-side {
        flex-grow: 1;
        margin-left: 20px;
        margin-right: 20px;
    }
    
     

    label {
        text-transform: uppercase;
        color: #999;
        font-family: 'BenchNine';
        margin-bottom: 0;
    }
}


.heater-time-section {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 20px;


      .dropdown {
        display: inline-block;
        margin-right: 5px;
        }

      .react-datepicker-wrapper {
        position: absolute;
        right: 50%;
        visibility: hidden;
    }

    .rc-time-picker-clear {
        display: none;
    }
    

    input[type=text] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        text-align: center;
        width: 120px;
        height: 35px;
        border: 1px solid #d9d9d9;
        color: #666;
        font-size: 16px;
        border-radius: 3px;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    
}

.heater-info-section {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 12px;
    color: #555;

    .pre-post-warm {
        text-decoration: underline;
        color: blue;

        &:hover {
            cursor: pointer;
        }
    }
}


.time-picker-popup {
    &.rc-time-picker-panel {
        width: 240px;

    }
    .rc-time-picker-panel-input-wrap {
        display: none;
    }
    
    &.rc-time-picker-panel-narrow {
        max-width: 240px;
    }

    .rc-time-picker-panel-select:first-child {
        width: 130px;
    }
    
    .rc-time-picker-panel-select {
        width: 105px;
        max-height: 240px;
        font-size: 30px;

        li:focus, li:active:focus {
            outline: none;
            box-shadow: none;
          }
    }

    .rc-time-picker-panel-select li {
        padding: 20px 0px 0px 30px;
        height: 60px;
    }
    li.rc-time-picker-panel-select-option-selected {
        background: var(--action-color);
        color: white;
        font-weight: bold;
    }

}

.pre-post-modal {
    .modal-content {
        min-height: 400px;

        label {
            text-align: center;
            width: 100%;
            font-size: 18px;
        }

        .ok-button {
            @include confirm-button;
            margin-top: 40px;
            background-color: white;
            color: var(--action-color);
            border: 1px solid var(--action-color);

            &:hover {
                background-color: #dddddd;
                color: var(--action-color);
                border: 1px solid var(--action-color);
            }
        }

        .post-warming-label {
            margin-top: 30px;
        }

        .explainer {
            font-size: 11px;
            text-align: center;
            max-width: 230px;
            margin-left: auto;
            margin-right: auto;
            color: #777;
            margin-top: -8px;
            margin-bottom: 10px;
        }

        .input-row {
            display: flex;
            font-size: 30px;
            width: 170px;
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
            align-items: center;
            
            .input-button {
                color: var(--action-color);
                &:hover {
                    cursor: pointer;
                }

                &.disabled {
                    color: #aaaaaa;
                }
            }

            

            .value {
                margin-left: 20px;
                margin-right: 20px;
                font-size: 40px;
                text-align: center;
                width: 100px;
            }
        }
    }
}

/* Disable hover styles on ios */
.enable-hover-effects {
    .rc-time-picker-panel-select li:hover {
        background: #d9d9d9;
        color: #212529;
        font-weight: normal;
    }
    
    li.rc-time-picker-panel-select-option-selected:hover {
        background: var(--action-color);
        color: white;
        font-weight: bold;
    }
}

.disable-hover-effects {
    .rc-time-picker-panel-select li:hover {
        background: #ffffff;
    }
    
    li.rc-time-picker-panel-select-option-selected:hover {
        background: var(--action-color);
    }

    .start-charging-view .slider-value-row .charge-type-btn:hover {
        background-color: white;
                color: #999999;
    }
}

/* ios 1px midalignment fix */ 
.ios-platform {
    .departure-time-section {
        .dropdown {
            input {
                position: relative;
                top: -1px;
            }

        }
    }
}