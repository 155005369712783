@import "common.scss";

.about-view {
    .content {
        width: 80%;
        min-width: 310px;
        margin-left: auto;
        margin-right: auto;
        min-height: 550px;

        h1 {
            border-top: 1px solid #dddddd;
            margin-top: 15px;
            padding-top: 15px;
            font-size: 20px;

            &:first-child {
                border-top: none;
            }
        }

        .title {
            margin-top: 10px;
            color: #868686;
            font-size: 12px;
        }

        .value {

            &.multiline {
                span {
                    display: block;
                }
            }

            a {
                color: inherit;
            }

            .link {
                margin-top: 5px;
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

    }

}