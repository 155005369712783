@import "common.scss";

.outlet-info-view {
    .content {
        width: 80%;
        min-width: 310px;
        margin-left: auto;
        margin-right: auto;
        min-height: 550px;

        @include property-table;

        .bg-plate {
            margin-bottom: 30px;
            box-shadow: none;
            border: 1px solid #d8d8d8;
        }

        h2 {
            font-size: 24px;
            margin-left: 20px;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .prop-group {
            padding-top: 13px;
            padding-bottom: 13px;
        }



        .prop-touple {
            margin-left: 20px;
        }
    }

    
}