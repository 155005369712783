@import "common.scss";

.history-view {
    .content {
        width: 100%;
        min-width: 310px;
        margin-left: auto;
        margin-right: auto;
        min-height: 550px;
        padding-bottom: 20px;

        .prop-table {
            margin-top: 20px;
            @include property-table;
        }


        /*.navx {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            margin-bottom: 0;
            margin-top: 20px;
            margin-bottom: 30px;
            list-style: none;
            border-bottom: 1px solid #ddd;
    
            .nav-item {
    
                .nav-link {
                    color: #555;
                    padding: 0;
                    padding-bottom: 5px;
                    &.active {
                        color: #333;
                        font-weight: bold;
                        border-bottom: 2px solid #333;
                    }
                }
            }
        }*/

        .fetch-more-button {
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
            display: block;
            @include rounded-secondary-button;
        }

        .fetch-spinner {
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-top: 20px;
            opacity: .4;
        }

        .receipt-card {
            margin-top: 10px;
            background-color: #fbfbfb;
            border: 1px solid #ddd;

            

            .card-body {
                padding: 0;
            }

          /*  .receipt-bg {
                height: 100%;
                width: 100%;
                stroke: #ddd;
            }
            */

            .receipt-summary {
                display: flex;
                
                align-items: center;
                margin-bottom: 0;

                &.card-header {
                    background-color: #fbfbfb;
                    padding: 10px 2%;
                }
                .receipt-summary-date-number {
                    display: flex;
                    flex-direction: column;
                    min-width: 0;

                    .receipt-summary-date {
                        font-size: 14px;
                        white-space: nowrap;
                        
                    }
                    .receipt-summary-number {
                        font-size: 12px;
                        color: #777;
                    }

                }

                .credit-receipt {
                    margin-left: 10px;
                    padding-left: 2px;
                    padding-right: 2px;
                    background-color: #333;
                    color: white;
                    font-weight: bold;
                }

                .icon {
                    min-width: 0;
                    width: 60px;

                    img {
                        //margin-left: 112px;
                        width: 40px;
                        border: 1px solid #ddd;

                        &.epspot {
                            border: none;
                            width: 25px;
                            margin-left: 8px;
                        }
                    }
                    .swish {
                        //width: 59px;
                        height: 20px;
                        margin-left: 10px;
                        background-image: url(../resources/Swish_Symbol_SVG.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }

                .receipt-summary-amount {
                    min-width: 0;
                    flex-grow: 1;
                    text-align: end;
                    font-size: 18px;
                    font-weight: 500;
                }

                .amount-with-refund {
                    margin-right: 10px;
                    font-size: 13px;
                    color: #777777;
                    text-decoration: line-through;
                }
            }
        }

        .session-card {
            margin-top: 10px;
            background-color: #fbfbfb;
            border: 1px solid #ddd;

            

            .card-body {
                padding: 0;
            }

           /* .receipt-bg {
                height: 100%;
                width: 100%;
                stroke: #ddd;
            }*/

            .session-summary {
                display: flex;
                align-items: center;
                margin-bottom: 0;

                &.card-header {
                    background-color: #fbfbfb;
                    padding: 10px 2%;

                    svg {
                        fill: #f1f1f1;
                        stroke: #545353;
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }
                }
                .session-summary-time-info {
                    display: flex;
                    flex-direction: column;
                    width: 225px;

                    .session-summary-date {
                        font-size: 14px;
                        
                    }
                    .session-summary-time-dur {
                        font-size: 12px;
                        color: #777;
                    }

                }


                .session-summary-amount {
                    
                    text-align: end;
                    font-size: 18px;
                    font-weight: 500;
                    flex-grow: 1;

                    .session-summary-energy {
                        .unit {
                            color: #777;
                            font-size: 12px;
                        }
                    }
                    .session-summary-sek {
                        font-size: 12px;
                        color: #777;
                    }
                }
            }
        }


    }
    
    .section-header {
        font-size: 30px;
        margin-top: 30px;  
    }

    p {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .no-history {
        text-align: center;
        margin-top: 60px;
        font-size: 20px;
    }

    

}