@import "common.scss";

.tariff {
    position: relative;
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    min-width: 160px;
    min-height: 60px;
    display: inline-block;
    text-align: initial;
    font-size: 14px;

    .title {
        background-color: #a6c9a50d;
        border-bottom: 1px solid #ededed;
        width: 100%;
        padding-left: 7px;
        padding-right: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        color: #459d43;
        align-items: center;

        .coins {
            margin-right: 3px;
        }
        .help-text-button {
            margin-left: auto;
        }
    }

    .values {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        .fee {
            text-align: right;
            font-weight: 500;
            color: #333;
            font-size: 16px;
        }
        .fee-spec {
            color: #666;
        }
    }

    .high-tariff-info {
        padding: 8px;
        text-align: center;
        color: darkred;
    }
}
