.top-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: calc(-100% + 50px);
    display: flex;
    flex-direction: column;
    pointer-events: none;

    &.show {
        top: -8px;
        transition: top 400ms;
        z-index: 1;

        .content-area {
            -webkit-box-shadow: 0px 3px 14px 0px rgba(0,0,0,0.45);
            -moz-box-shadow: 0px 3px 14px 0px rgba(0,0,0,0.45);
            box-shadow: 0px 3px 14px 0px rgba(0,0,0,0.45);
            pointer-events: initial;
        }

        .control-area {
            .profile-button {
                -webkit-box-shadow: -4px 7px 9px -3px rgba(0, 0, 0, 0.45);
                -moz-box-shadow: -4px 7px 9px -3px rgba(0, 0, 0, 0.45);
                box-shadow: -4px 7px 9px -3px rgba(0, 0, 0, 0.45);
                background-color: white;
                color: #626262;
                transition: background-color 400ms;
                
                
    
                path {
                    fill: white;
                    stroke: #626262;
                }  
            }
        }
        
    }
    &.hide {
        
        top: calc(-100% + 50px);
        transition: top 400ms;
    }

    .content-area {
        flex-grow: 1;
        background-color: white;
        height: calc(100% - 50px); // 50px - height of control-area
        position: relative;
        overflow: auto;
    }

    .control-area {
        background-color: transparent;
        
        height: 50px;

        .profile-button {
            max-width: 70px;
            min-width: 55px;
            height: 54px;
            font-size: 28px;
            position: absolute;
            pointer-events: initial;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #626262;
            color: white;
            padding-top: 3px;
            padding-left: 2px;
            padding-right: 2px;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;

            .profile-button-container {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
    
            path {
                fill: #626262;
                stroke: #fefefe;
                stroke-width: 16px;
            }    
    
            .text {
                padding-inline: 5px;
                max-width: 70px;
                text-overflow: ellipsis;
                font-size: 14px;
                font-family: 'BenchNine';
                text-transform: uppercase;
                margin-top: -1px;
                overflow: hidden;
                text-align: center;
                height: 20px;
            }
        }
    }

    
}
